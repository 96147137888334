import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 607.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,607.000000) scale(0.100000,-0.100000)">

<path d="M0 3035 l0 -3035 5400 0 5400 0 0 3035 0 3035 -5400 0 -5400 0 0
-3035z m2916 2245 c139 0 154 -2 163 -18 13 -24 28 -135 46 -337 7 -88 19
-194 25 -235 11 -73 35 -289 47 -422 l6 -67 -59 -16 c-33 -8 -72 -19 -89 -24
-138 -42 -336 -84 -345 -75 -6 6 -13 40 -17 77 -8 93 -22 90 -37 -8 -15 -92
-15 -93 -59 -109 -49 -19 -149 -42 -262 -62 -44 -7 -109 -21 -145 -29 -36 -9
-82 -18 -104 -20 l-39 -5 6 68 c3 37 10 121 16 187 6 66 15 154 20 195 14 99
27 214 51 455 11 110 24 229 30 265 5 36 13 90 17 120 l8 55 105 6 c58 4 186
5 284 3 99 -2 248 -4 332 -4z m1618 -7 l38 -6 -5 -61 c-38 -394 -71 -673 -80
-683 -12 -12 -253 -74 -382 -99 -77 -14 -185 -40 -240 -57 -55 -16 -193 -53
-307 -82 -158 -40 -208 -49 -208 -39 0 14 9 42 132 429 114 361 180 558 195
589 l15 29 402 -6 c220 -4 418 -10 440 -14z m1509 -4 c326 -43 484 -161 620
-466 55 -124 93 -327 65 -350 -18 -16 -175 -43 -244 -43 -63 0 -145 -16 -199
-40 -22 -9 -58 -23 -80 -31 -22 -7 -44 -16 -50 -20 -5 -3 -32 -12 -59 -19 -45
-12 -50 -12 -62 5 -8 10 -14 38 -14 62 0 103 -88 237 -175 263 -22 7 -100 15
-172 17 -154 6 -145 12 -157 -112 -17 -174 -38 -343 -47 -370 -10 -34 -22 -38
-359 -114 -244 -56 -272 -56 -269 -3 1 15 -1 92 -5 172 -10 212 -13 320 -9
283 2 -18 9 -33 14 -33 12 0 0 72 -14 81 -6 4 -6 12 -1 20 5 8 14 79 21 159
20 251 51 531 59 539 18 19 994 19 1137 0z m1233 5 l201 -7 7 -60 c3 -33 6
-302 6 -598 0 -420 -3 -538 -12 -542 -7 -3 -3 -6 10 -6 41 -2 20 -38 -29 -51
-19 -5 -72 -20 -119 -35 -107 -33 -260 -74 -324 -88 -43 -9 -50 -8 -62 8 -8
10 -14 36 -14 56 0 20 -9 66 -20 101 -11 34 -20 88 -20 118 0 55 34 371 85
780 14 113 28 230 31 260 7 62 19 83 43 76 9 -3 107 -8 217 -12z m1144 -14
c177 -39 272 -105 347 -238 51 -91 63 -148 63 -298 0 -172 -12 -207 -99 -295
-64 -64 -152 -118 -281 -173 -75 -31 -295 -91 -480 -130 -96 -20 -196 -41
-221 -47 -52 -12 -120 -20 -101 -12 9 3 12 38 12 123 0 137 -7 129 120 130
146 1 242 24 291 69 33 30 59 103 59 164 0 120 -50 157 -214 157 -50 1 -122 5
-161 10 -43 5 -71 5 -73 -1 -2 -5 -8 -9 -13 -9 -5 0 -9 117 -9 280 l0 280 38
1 c20 1 116 5 212 9 228 10 405 3 510 -20z m-3956 -1015 c5 -15 -3 -129 -23
-295 -12 -99 -14 -121 -41 -380 -23 -222 -36 -340 -50 -455 -6 -44 -17 -138
-25 -210 -9 -71 -18 -145 -21 -162 l-5 -33 -289 0 c-176 0 -291 4 -295 10 -5
8 5 116 29 320 6 50 18 171 21 200 1 14 5 54 9 90 8 66 35 311 62 560 24 220
26 229 40 241 13 10 306 88 434 114 91 19 146 19 154 0z m2273 -31 c7 -19 -14
-292 -29 -379 -17 -99 -60 -274 -89 -368 -88 -276 -281 -512 -519 -633 -86
-44 -157 -69 -266 -96 -85 -20 -117 -21 -656 -25 l-568 -4 0 43 c0 24 7 106
15 183 9 77 22 205 30 285 33 320 51 480 61 511 6 21 41 35 129 49 39 6 111
23 160 37 152 42 399 102 423 103 12 0 22 -5 22 -11 0 -10 -46 -439 -54 -504
-6 -52 -1 -55 94 -55 101 0 220 20 283 48 87 39 141 121 182 275 24 93 55 279
55 334 0 17 8 37 18 45 15 14 641 176 685 177 10 1 20 -6 24 -15z m-2917 -104
c0 -10 -56 -191 -70 -225 -5 -11 -52 -155 -105 -320 -54 -165 -138 -424 -189
-575 l-91 -275 -265 0 -265 0 -7 40 c-4 22 -21 173 -38 335 -17 162 -35 329
-40 370 -5 41 -14 122 -21 180 -20 193 -26 171 -74 -295 -14 -132 -29 -271
-35 -310 -5 -38 -14 -116 -21 -173 -7 -56 -17 -110 -23 -118 -10 -13 -48 -14
-295 -8 l-283 7 6 31 c4 17 8 74 11 126 2 52 9 127 14 165 6 39 18 140 26 225
17 168 42 350 51 373 7 19 53 53 84 61 14 4 34 11 45 16 17 7 133 37 275 70
19 5 178 45 353 89 175 45 327 81 338 81 11 0 23 -10 28 -22 l9 -23 12 27 c15
32 57 53 125 63 28 4 83 16 124 27 86 23 278 66 304 67 9 1 17 -3 17 -9z
m4646 -145 c256 -113 363 -277 334 -512 -28 -228 -93 -369 -235 -507 -119
-116 -183 -151 -345 -186 -186 -41 -202 -42 -847 -47 -629 -4 -633 -4 -633 16
0 84 92 880 104 900 8 13 92 23 201 25 61 1 100 7 120 18 101 54 157 76 305
115 419 111 810 200 882 202 41 1 73 -6 114 -24z m-5840 -1705 c7 -102 14
-223 15 -270 l4 -85 -55 0 -55 0 -7 40 c-4 22 -7 87 -7 144 -1 57 -4 102 -7
98 -8 -8 -114 -255 -114 -266 0 -13 -26 -21 -66 -21 -30 0 -36 5 -57 48 -13
26 -41 91 -62 145 -21 53 -42 97 -47 97 -4 0 -8 -66 -8 -146 l0 -147 -52 7
c-29 4 -54 9 -56 11 -5 4 17 447 24 493 5 31 6 32 58 32 l52 0 71 -182 c39
-101 74 -192 78 -202 8 -26 18 -13 45 64 13 36 26 70 30 75 3 6 22 51 40 100
18 50 37 94 42 100 4 5 8 17 8 26 0 19 22 26 80 25 l35 -1 11 -185z m369 166
c58 -25 66 -34 52 -57 -9 -15 -9 -21 1 -27 9 -6 11 -29 7 -81 -4 -60 -8 -75
-27 -91 l-23 -18 28 -27 c26 -27 34 -45 20 -45 -5 0 -7 -36 -7 -80 1 -44 -2
-82 -6 -85 -4 -2 -25 35 -48 83 -45 99 -68 129 -97 134 -19 3 -20 -4 -25 -112
l-5 -115 -62 -3 -63 -3 0 277 0 277 108 -6 c65 -3 125 -12 147 -21z m1749 3
c-1 -5 -2 -22 -2 -39 l-2 -30 -65 0 -65 0 -2 -227 -3 -228 -62 -3 -63 -3 -2
228 -3 228 -40 1 c-88 2 -85 1 -85 43 0 29 5 41 18 44 33 9 377 -4 376 -14z
m352 -164 c34 -99 76 -218 93 -265 l30 -85 -51 -8 c-30 -4 -55 -3 -62 2 -7 6
-18 33 -25 60 -7 27 -18 54 -24 59 -7 5 -50 6 -97 3 l-84 -6 -16 -50 c-8 -27
-19 -56 -23 -62 -11 -17 -117 -18 -117 -1 0 12 176 490 192 521 8 15 20 17 65
15 l56 -3 63 -180z m687 158 c71 -29 103 -101 72 -166 -9 -20 -29 -40 -46 -47
-30 -13 -40 -40 -15 -40 8 0 31 -15 50 -34 33 -30 36 -38 36 -89 0 -50 -3 -58
-34 -85 -50 -44 -97 -55 -226 -56 l-115 -1 -3 260 c-1 143 0 266 3 274 4 11
27 12 123 6 64 -4 134 -14 155 -22z m297 20 c1 -5 2 -92 3 -195 3 -171 5 -190
24 -215 25 -35 60 -48 106 -39 60 11 67 36 71 253 l4 188 30 3 30 3 1 -221 c0
-121 2 -224 5 -229 10 -16 -33 -58 -75 -75 -53 -21 -177 -23 -216 -3 -38 20
-69 55 -88 99 -12 30 -15 79 -15 238 l0 200 60 0 c33 0 60 -3 60 -7z m588 -95
c38 -57 86 -131 107 -165 21 -35 42 -63 47 -63 6 0 8 65 6 160 l-3 160 30 1
c17 1 44 2 60 3 l30 1 0 -270 0 -270 -45 -3 c-25 -1 -50 1 -55 5 -6 5 -33 42
-60 83 -26 41 -52 77 -57 78 -4 2 -8 8 -8 12 0 5 -21 41 -47 81 l-48 71 -3
-166 -2 -167 -58 3 -57 3 -3 267 -2 267 37 4 c21 2 44 4 50 5 7 1 43 -44 81
-100z m653 82 c37 -13 85 -50 109 -86 12 -17 15 -57 14 -181 l0 -161 -30 -26
c-65 -59 -99 -69 -236 -74 l-128 -4 0 277 0 277 118 -5 c64 -3 133 -10 153
-17z m371 -207 l3 -228 93 -3 92 -3 0 -45 0 -45 -152 3 -153 3 -3 267 -2 267
37 4 c21 2 48 4 60 5 l23 2 2 -227z m593 177 l0 -45 -107 -3 -108 -3 0 -59 0
-60 91 0 92 0 -7 -31 c-3 -17 -6 -38 -6 -45 0 -10 -22 -14 -82 -16 l-83 -3 0
-70 0 -70 110 0 110 0 3 -48 3 -47 -177 2 -177 3 -4 215 c-2 118 -1 238 2 267
7 62 2 61 205 59 l135 -1 0 -45z m-4559 21 c92 -42 137 -128 136 -260 -1 -85
-16 -133 -62 -185 -47 -53 -96 -68 -242 -74 l-128 -5 0 272 0 271 128 0 c102
0 135 -4 168 -19z m439 9 c3 -6 -1 -13 -10 -16 -14 -6 -14 -8 2 -20 16 -12 16
-13 1 -8 -14 5 -17 0 -15 -31 2 -20 9 -41 17 -47 12 -8 12 -9 -3 -4 -14 5 -16
1 -12 -22 l5 -27 -11 28 c-6 15 -15 27 -21 25 -11 -4 -65 -162 -60 -177 2 -6
25 -11 52 -11 43 0 48 -2 52 -25 3 -14 1 -25 -6 -25 -6 0 -5 -4 3 -9 14 -9
-17 -16 -103 -23 -27 -3 -31 -9 -51 -68 l-22 -65 -61 -3 c-34 -2 -62 1 -62 6
0 5 24 70 53 143 29 74 68 172 87 219 70 180 64 170 114 170 25 0 48 -5 51
-10z m4386 1 c-7 -5 -13 -20 -13 -35 0 -15 -6 -26 -14 -26 -16 0 -34 -31 -34
-59 0 -11 12 -33 26 -48 21 -23 25 -35 21 -65 -3 -24 0 -44 10 -58 15 -21 15
-22 -8 -15 -48 13 -134 62 -152 87 -25 33 -33 100 -17 139 21 51 83 89 144 89
33 0 46 -3 37 -9z m127 -12 c27 -10 29 -39 4 -69 l-18 -22 -38 23 c-20 12 -34
25 -29 28 11 7 11 27 1 37 -24 24 18 25 80 3z m-5603 -59 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1228 -263 c37 -97 67 -181
67 -187 0 -11 -75 -12 -104 -1 -13 5 -24 28 -36 76 -10 39 -15 75 -13 82 3 7
0 13 -6 13 -6 0 -9 16 -6 42 2 23 0 59 -4 80 -7 30 -6 38 5 38 16 0 19 16 5
24 -5 3 -9 23 -9 43 l0 38 17 -36 c10 -19 47 -114 84 -212z m-141 131 c-8 -8
-9 -4 -5 13 4 13 8 18 11 10 2 -7 -1 -18 -6 -23z m4494 -40 c61 -42 80 -118
45 -186 -22 -43 -46 -67 -80 -77 -22 -7 -23 -5 -19 29 2 20 0 36 -4 36 -11 0
-7 89 4 96 4 3 3 16 -2 29 -6 15 -6 34 0 49 5 15 5 27 -3 35 -21 21 25 12 59
-11z m-241 -171 c11 -8 41 -22 66 -31 36 -14 51 -15 68 -7 17 10 20 9 15 -4
-4 -8 -12 -15 -20 -15 -20 0 -17 -20 4 -21 16 -1 16 -2 -1 -6 -15 -4 -16 -7
-5 -20 12 -15 7 -16 -45 -10 -71 7 -147 35 -147 54 0 18 28 73 38 73 4 0 16
-6 27 -13z m-5265 -99 c0 -4 -9 -6 -20 -5 -17 1 -20 8 -20 42 l1 40 19 -35
c11 -19 19 -38 20 -42z m-300 -361 c0 -168 4 -187 42 -187 28 0 24 -46 -4 -54
-43 -11 -78 -6 -98 14 -19 19 -20 31 -18 198 l3 177 30 6 c17 4 33 7 38 8 4 0
7 -72 7 -162z m170 148 c17 -20 5 -62 -20 -70 -46 -15 -76 17 -59 63 10 26 59
31 79 7z m457 -125 c4 0 21 5 38 11 90 33 155 -89 105 -199 -15 -34 -29 -47
-65 -63 -43 -19 -63 -20 -143 -3 l-22 4 2 188 3 187 35 5 35 5 3 -67 c2 -38 6
-68 9 -68z m283 -24 c0 -85 3 -161 6 -170 3 -9 17 -16 30 -16 19 0 24 -5 24
-24 0 -21 -6 -25 -41 -31 -33 -6 -47 -3 -68 12 l-26 20 -3 173 c-3 187 -2 190
51 190 l27 0 0 -154z m4851 -16 c3 -91 9 -175 13 -187 7 -18 3 -25 -20 -35
-19 -9 -28 -10 -31 -2 -2 6 -10 20 -18 30 -14 18 -14 18 -30 -3 -18 -25 -71
-31 -107 -12 -43 23 -63 131 -33 189 19 38 69 72 108 75 32 2 32 3 35 59 l3
57 37 -3 37 -3 6 -165z m-3983 112 l3 -32 -75 0 -76 0 0 -45 0 -45 61 0 c60 0
60 0 57 -27 -3 -26 -7 -28 -58 -33 l-55 -5 -3 -78 -3 -78 -42 3 -42 3 -3 182
-2 182 107 3 c60 2 113 3 118 2 6 0 11 -15 13 -32z m1547 30 c6 -4 38 -81 72
-172 33 -91 64 -173 68 -183 7 -16 3 -18 -41 -15 -47 3 -49 4 -60 43 l-11 40
-65 3 -65 3 -13 -46 c-13 -45 -13 -45 -57 -45 -52 0 -53 -14 14 160 25 63 54
139 65 168 18 48 22 52 51 52 18 0 37 -4 42 -8z m-3756 -36 c40 -39 43 -91 6
-130 -29 -31 -31 -48 -9 -66 19 -17 74 -127 66 -134 -3 -3 -22 -6 -43 -6 -34
0 -38 3 -54 43 -26 66 -63 117 -85 117 -18 0 -20 -7 -20 -75 l0 -75 -34 0
c-22 0 -36 6 -40 16 -9 23 -7 329 2 338 4 4 46 6 94 4 80 -3 89 -5 117 -32z
m2851 -22 c0 -31 2 -34 31 -34 27 0 30 -3 27 -27 -2 -22 -9 -29 -30 -31 -28
-3 -28 -4 -28 -77 l0 -74 33 -3 c26 -2 33 -8 35 -30 3 -22 -2 -29 -23 -34 -40
-10 -105 2 -120 23 -10 12 -14 46 -15 101 0 75 -2 84 -20 89 -28 8 -29 63 0
63 14 0 22 10 28 33 6 25 15 33 38 38 39 7 44 3 44 -37z m1527 -34 c28 0 34
-3 29 -16 -3 -9 -6 -22 -6 -29 0 -8 -12 -15 -27 -17 -27 -3 -28 -5 -31 -68 -3
-69 10 -93 47 -83 14 3 20 -3 25 -22 4 -15 4 -30 1 -33 -3 -3 -29 -7 -58 -10
-43 -3 -56 0 -74 18 -20 18 -23 31 -23 106 0 81 -1 85 -25 94 -35 13 -36 60
-1 60 18 0 25 7 29 30 5 23 14 31 39 38 33 8 33 8 36 -30 3 -36 5 -38 39 -38z
m1244 0 c27 0 30 -3 27 -27 -2 -22 -9 -29 -31 -31 l-28 -3 3 -77 3 -77 31 -3
c42 -4 54 -39 18 -53 -54 -20 -121 -2 -129 35 -3 12 -6 55 -7 96 -3 69 -5 75
-25 78 -18 3 -23 10 -23 33 0 24 4 29 24 29 19 0 25 6 28 28 2 21 11 30 38 38
l35 12 3 -39 c3 -36 5 -39 33 -39z m-2311 45 c17 -9 30 -17 30 -19 0 -1 -9
-13 -20 -26 -18 -21 -23 -22 -44 -10 -56 29 -62 -23 -9 -80 32 -36 38 -39 51
-26 12 11 20 12 39 3 21 -9 24 -16 19 -41 -4 -20 -1 -35 11 -48 13 -15 14 -23
5 -43 -13 -29 -24 -31 -56 -10 -22 15 -27 14 -60 -6 -54 -31 -120 -23 -159 21
-25 28 -28 39 -24 73 3 23 16 50 30 66 19 19 24 33 20 53 -8 42 4 76 35 92 35
20 95 20 132 1z m-2407 -52 c4 -2 7 -49 7 -103 0 -54 5 -111 10 -126 12 -32
12 -32 -28 -40 -25 -5 -32 -3 -32 9 0 24 -19 31 -38 14 -28 -23 -53 -28 -90
-16 -25 8 -39 22 -53 53 -23 51 -24 84 -3 136 16 40 51 73 89 83 21 5 124 -2
138 -10z m1489 11 l46 -6 5 -107 c3 -58 9 -118 13 -133 5 -24 3 -28 -22 -34
-21 -6 -31 -2 -46 15 -16 21 -19 21 -34 7 -25 -26 -63 -30 -100 -12 -88 42
-78 209 16 258 38 20 54 21 122 12z m281 -13 c7 -7 7 -18 -1 -35 -10 -21 -15
-24 -51 -19 -55 8 -56 -19 -2 -47 72 -36 91 -95 48 -145 -29 -34 -96 -44 -150
-22 -35 15 -36 17 -25 42 11 23 15 25 60 19 40 -6 48 -4 48 10 0 8 -19 28 -42
42 -24 14 -49 33 -55 42 -23 28 -15 71 18 104 28 29 34 30 86 25 31 -3 60 -10
66 -16z m2155 -21 c5 0 15 6 21 14 20 24 69 30 96 12 28 -19 52 -21 57 -5 6
19 85 16 105 -4 15 -15 18 -36 18 -140 l0 -122 -37 -3 -38 -3 0 104 c0 73 -4
108 -13 115 -33 28 -57 -31 -57 -139 l0 -80 -37 3 -38 3 -3 105 c-3 116 -13
138 -43 101 -16 -19 -19 -39 -19 -117 l0 -94 -39 0 -39 0 -5 108 c-3 59 -8
121 -12 139 -6 28 -4 32 16 35 15 3 31 -3 41 -14 9 -10 21 -18 26 -18z m557
32 l40 -7 6 -110 c3 -61 9 -120 13 -132 7 -18 3 -22 -25 -28 -18 -4 -35 -4
-36 1 -12 33 -22 38 -40 22 -90 -82 -201 48 -149 173 31 75 88 99 191 81z
m-5093 -32 c42 -51 8 -121 -70 -143 -53 -16 -64 -32 -29 -48 17 -8 35 -8 62 0
45 12 44 12 47 -19 3 -22 -3 -26 -47 -37 -108 -28 -175 20 -175 123 0 93 66
159 150 150 30 -3 50 -11 62 -26z m309 -47 c2 -124 4 -134 22 -144 23 -14 21
-46 -3 -54 -30 -10 -79 4 -90 25 -6 10 -10 71 -10 134 l0 116 40 0 40 0 1 -77z
m1020 51 c25 -23 25 -74 -2 -107 -11 -15 -39 -32 -62 -38 -55 -16 -62 -23 -41
-43 13 -14 27 -16 64 -11 39 5 48 4 54 -11 10 -27 -9 -38 -79 -42 -75 -5 -105
9 -131 60 -21 40 -14 119 13 156 43 60 137 78 184 36z m2389 -63 c0 -102 5
-121 31 -121 29 0 39 32 39 124 l0 86 44 0 44 0 4 -117 c3 -65 8 -125 12 -134
5 -13 0 -19 -22 -27 -31 -11 -52 -1 -52 25 0 17 -4 17 -42 -7 -42 -26 -79 -25
-112 1 -25 20 -26 23 -26 140 l0 119 40 0 40 0 0 -89z m628 69 c39 -24 52 -54
52 -123 0 -94 -65 -157 -149 -142 -67 12 -101 58 -101 136 0 117 105 186 198
129z m1230 9 c18 -6 35 -22 42 -40 23 -55 -19 -106 -102 -124 -19 -3 -28 -12
-28 -25 0 -24 30 -33 80 -25 32 5 41 3 46 -10 10 -24 -7 -35 -63 -41 -70 -9
-117 9 -137 50 -41 87 -2 191 80 216 40 12 47 12 82 -1z m-4118 -220 c20 -36
-1 -87 -51 -127 -14 -10 -20 -10 -33 3 -14 15 -14 18 0 41 11 16 15 36 11 58
-6 41 -2 46 34 46 18 0 32 -7 39 -21z m2410 -379 l0 -170 24 0 c16 0 26 -7 30
-22 8 -31 -6 -41 -59 -42 -38 -1 -50 4 -66 23 -18 22 -19 38 -17 194 l3 171
25 7 c63 18 60 25 60 -161z m-2426 13 c22 -68 43 -123 47 -123 3 0 24 55 46
123 l41 122 41 3 c27 2 41 -1 41 -9 0 -6 -30 -92 -67 -191 l-68 -179 -35 6
c-19 4 -38 12 -42 18 -11 18 -128 335 -128 347 0 7 17 10 42 8 l42 -3 40 -122z
m512 105 c-3 -13 -6 -29 -6 -35 0 -9 -16 -13 -45 -13 l-45 0 0 -156 0 -155
-42 3 -43 3 -3 152 -3 152 -42 3 c-31 2 -43 8 -45 21 -9 44 0 47 144 47 l136
0 -6 -22z m114 -98 c0 -130 12 -173 51 -185 32 -11 64 2 77 30 7 15 12 77 12
150 l0 125 45 0 45 0 0 -131 c0 -147 -12 -188 -63 -220 -88 -53 -225 -16 -241
64 -7 34 -14 260 -9 275 3 7 22 12 44 12 l39 0 0 -120z m632 95 c38 -27 52
-73 38 -122 -13 -48 -50 -75 -112 -81 l-48 -5 0 -73 0 -74 -45 0 -45 0 0 191
0 191 93 -4 c72 -3 99 -8 119 -23z m680 -32 c3 -35 6 -38 36 -41 25 -3 32 -7
28 -19 -3 -8 -6 -21 -6 -29 0 -9 -11 -14 -30 -14 l-31 0 3 -72 c3 -73 3 -73
33 -76 32 -4 45 -16 45 -44 0 -23 -79 -35 -120 -18 -35 14 -39 27 -42 130 -3
70 -5 75 -26 78 -19 3 -23 9 -20 35 2 24 8 32 22 32 14 0 22 10 28 32 6 24 15
33 36 36 15 2 30 5 34 6 4 0 8 -16 10 -36z m-272 -53 l11 -20 21 20 c26 24 88
28 88 6 0 -32 -20 -66 -39 -66 -36 0 -51 -37 -51 -128 l0 -83 -42 3 -43 3 -5
115 c-3 63 -9 123 -14 132 -6 14 -2 20 15 27 36 15 47 13 59 -9z m594 10 c26
-9 26 -11 26 -109 0 -55 5 -112 12 -126 13 -30 11 -33 -31 -41 -27 -6 -33 -4
-38 15 -7 26 -7 26 -43 0 -36 -27 -86 -20 -120 16 -19 20 -25 40 -28 89 -5 75
13 117 61 146 35 22 118 27 161 10z m-763 -17 c35 -21 53 -62 53 -122 0 -91
-48 -143 -132 -143 -88 1 -122 40 -122 138 0 63 11 89 48 121 33 28 110 31
153 6z"/>
<path d="M7567 3713 c-4 -3 -7 -26 -7 -50 0 -42 -8 -105 -31 -257 -9 -56 -9
-70 2 -78 8 -5 97 -7 209 -5 209 5 237 11 298 62 43 36 66 111 63 204 -1 43
-61 104 -116 120 -43 12 -407 16 -418 4z"/>
<path d="M2870 2291 l0 -81 33 0 c51 1 67 20 67 77 0 57 -11 71 -64 80 l-36 6
0 -82z"/>
<path d="M3041 2304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4939 2219 c-15 -43 -24 -81 -21 -84 7 -7 97 -7 104 0 6 6 -39 145
-50 157 -4 3 -19 -29 -33 -73z"/>
<path d="M5637 2363 c-3 -5 -7 -40 -8 -78 -2 -66 -1 -70 20 -73 35 -5 79 17
85 43 8 29 -3 82 -19 95 -17 14 -72 24 -78 13z"/>
<path d="M5632 2063 l3 -78 42 1 c56 1 73 17 73 69 0 53 -33 85 -87 85 l-34 0
3 -77z"/>
<path d="M7187 2353 c-4 -3 -7 -87 -7 -185 l0 -178 38 0 c49 0 81 18 109 59
19 29 23 47 23 115 -1 104 -24 160 -74 181 -37 16 -78 19 -89 8z"/>
<path d="M3558 2354 c-5 -4 -8 -88 -8 -186 l0 -178 40 0 c93 0 132 51 133 170
1 129 -34 188 -115 197 -24 3 -46 1 -50 -3z"/>
<path d="M3496 1525 c-16 -16 -30 -87 -24 -126 3 -26 32 -36 57 -20 19 12 27
111 12 140 -14 25 -24 26 -45 6z"/>
<path d="M8466 1518 c-20 -28 -21 -108 -2 -133 31 -43 66 10 66 101 0 51 -2
54 -24 54 -14 0 -32 -10 -40 -22z"/>
<path d="M6116 1534 c-21 -63 -21 -64 19 -64 39 0 42 7 24 61 -18 53 -25 54
-43 3z"/>
<path d="M2280 1575 c0 -54 1 -55 28 -55 36 0 52 17 52 55 0 38 -16 55 -52 55
-27 0 -28 -1 -28 -55z"/>
<path d="M5607 1454 c-13 -14 -7 -62 9 -68 22 -9 52 -7 58 2 7 11 -58 74 -67
66z"/>
<path d="M3187 1522 c-19 -21 -23 -102 -7 -133 28 -51 73 16 68 99 -2 39 -7
48 -24 50 -11 2 -28 -6 -37 -16z"/>
<path d="M4733 1532 c-21 -13 -34 -64 -27 -109 8 -53 20 -62 47 -35 26 26 37
129 16 143 -17 11 -21 11 -36 1z"/>
<path d="M7706 1524 c-23 -23 -23 -142 1 -151 30 -12 53 32 53 103 0 57 -2 64
-19 64 -11 0 -27 -7 -35 -16z"/>
<path d="M2594 1504 c-12 -17 -14 -28 -6 -36 14 -14 50 -1 57 22 14 44 -25 55
-51 14z"/>
<path d="M3932 1518 c-19 -19 -15 -58 6 -58 25 0 42 19 42 47 0 25 -27 32 -48
11z"/>
<path d="M6968 1519 c-22 -12 -25 -105 -5 -133 19 -26 49 -16 59 19 12 47 -7
126 -31 125 -3 0 -14 -5 -23 -11z"/>
<path d="M8217 1527 c-21 -16 -32 -63 -17 -72 18 -11 60 23 60 49 0 28 -22 39
-43 23z"/>
<path d="M5290 1005 c0 -51 2 -55 23 -55 31 0 47 18 47 51 0 32 -23 59 -51 59
-16 0 -19 -7 -19 -55z"/>
<path d="M6297 952 c-11 -12 -17 -35 -17 -64 0 -58 8 -78 30 -78 25 0 42 48
38 106 -3 55 -22 68 -51 36z"/>
<path d="M5550 941 c-5 -11 -10 -38 -10 -61 0 -50 15 -80 39 -80 23 0 31 19
31 76 0 51 -14 84 -35 84 -8 0 -19 -9 -25 -19z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
